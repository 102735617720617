<template>
   <v-container>
      <div class="top-nav">
         <div class="top-nav-row">
            <div class="title">List Produk</div>
            <v-text-field dense clearable :style="{maxWidth:'360px'}" v-model="search_pro" hide-details placeholder="cari produk..." />
         </div>
         <v-slide-group show-arrows v-model="selected_cat" @change="getData" >
            <v-slide-item v-for="c in categorys" :key="c.id" v-slot="{ active, toggle }" >
               <v-btn class="mx-2" :input-value="active" active-class="purple white--text" depressed rounded @click="toggle">
                  {{ c.name }}
               </v-btn>
            </v-slide-item>
         </v-slide-group>
      </div>
      <v-progress-linear v-if="load_pro" color="deep-purple" height="3" indeterminate/>
      <v-row justify="space-around" class="mt-3">
         <v-col cols="12" sm="6" md="4" v-for="(p,i) in products" :key="i">
            <v-card max-width="320" class="mx-auto" >
               <template slot="progress"/>

               <v-img max-height="200" v-if="p.image" :src="base_image+p.image"/>

               <v-card-text class="subtitle-2 justify-center">{{p.name}}</v-card-text>

               <!-- <v-card-text>
                  <v-row align="center" class="mx-0" >
                     <div class="grey--text ms-4">4.5 (413)</div>
                  </v-row>

                  <div class="my-4 text-subtitle-1">$ • Italian, Cafe</div>

                  <div>Small plates, salads & sandwiches - an intimate setting with 12 indoor seats plus patio seating.</div>
               </v-card-text> -->

               <v-divider class="mx-4"/>
               <v-card-text>
                  <v-icon class="float-right" icon color="accent" @click="view_pro=p.id">mdi-dots-vertical</v-icon>
                  <div class="text-h6 orange--text" v-idr:full="p.price"/>
                  Stok <b class="primary--text" v-text="p.stock"/>
                  <v-rating v-model="p.star" @input="updStart(p)" color="amber" dense size="15"/>
               </v-card-text>

               <v-card-text class="d-flex pro-cart">
                  <div>
                     <v-text-field
                        v-model="p.quantity"
                        hide-details
                        dense
                        outlined
                        prepend-inner-icon="mdi-minus"
                        append-icon="mdi-plus"
                        @click:prepend-inner="p.quantity>1 && p.quantity--"
                        @click:append="p.quantity++"
                     />
                  </div>
                  <v-spacer />
                  <v-btn icon color="primary" outlined @click="addToCart(i)">
                     <v-icon>mdi-cart</v-icon>
                  </v-btn>
               </v-card-text>
            </v-card>
         </v-col>
      </v-row>
      <v-divider class="mt-3" />
      <div class="my-3 d-flex justify-center">
         <v-pagination v-model="page" :length="Math.ceil(total/limit)" v-on:input="chPage"/>
      </div>
      <product-fm :product_id="view_pro" v-on:close="closeDetail" />
   </v-container>
</template>

<script>
import CFG from '../config';
import api from '../api'
import ProductFm from '../components/ProductFm.vue';
export default {
   components: { ProductFm },
   name: 'cart',
   data: () => ({
      load_pro: false,
      search_pro: null,
      selected_cat: null,
      categorys: [],
      products: [],
      limit: 15,
      total: 0,
      page: 1,
      base_image: CFG.img_url,
      view_pro: null
   }),
   mounted() {
      api.get(`category?limit=50`).then(rsl => {
         if(rsl.data) this.categorys  = rsl.data;
      });
      this.getData();
   },
   watch: {
      search_pro() {
         clearTimeout(this.timer);
         this.page = 1;
         this.timer = setTimeout(this.getData, 600);
      }
   },
   methods: {
      updStart({id, ...data}) {
         api.edit('product', id, data).then(rsl => {
            if(rsl.status === 1) {
               this.$alert({title: rsl.msg, type: 'info', timer: 1000});
               this.getData();
            } else this.$alert('Ooops!', rsl.msg, 'error');
         });
      },
      getData() {
         this.load_pro = true;
         let url = `product?page=${this.page}&limit=${this.limit}&sort=star&desc=1`;
         if(this.search_pro) url+= `&name=${this.search_pro}`;
         if(this.selected_cat) url+= `&category=${this.categorys[this.selected_cat].id}`;
         api.get(url).then(rsl => {
            if(rsl.data) {
               this.products = rsl.data.map(x => ({...x, quantity: 1}));
               this.total = rsl.total;
            }
         }).finally(() => { this.load_pro = false });
      },
      closeDetail(refresh) {
         this.view_pro = null;
         if(refresh) this.getData();
      },
      chPage() {
         this.getData();
      },
      addToCart(i) {
         const data = this.products[i];
         this.products.splice(i,1,{...data,quantity:1});
         this.$store.commit('addCart', {...data, selected: true});
      }
   }
}
</script>
<style scoped>
.top-nav {border: 1px solid #ade;padding: 10px 15px;}
.top-nav-row { display: flex;margin-bottom: 10px;justify-content: space-between;}
.pro-qty-act {cursor: pointer;padding: 4px;}
.pro-cart > div {max-width: 130px}
.pro-cart > div >>> input {text-align: center;}
</style>