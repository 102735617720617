<template>
   <v-dialog :value="!!product_id" v-on:input="close" max-width="720" >
      <v-card>
         <v-card-title>
            {{title}} Product
            <v-spacer/>
            <v-icon color="red" @click="close">mdi-close</v-icon>
         </v-card-title>
         <v-divider class="mx-4 mb-4"></v-divider>
         <v-card-text class="row justify-space-between mx-0">
            <v-col v-if="edit" cols="12" md="7">
               <v-text-field v-model="fm.name" label="Nama" hide-details />
               <v-textarea rows="3" v-model="fm.description" label="deskripsi" hide-details />
               <v-text-field v-model="fm.stock" label="Stok" hide-details />
               <input-currency v-model="fm.capital" label="Modal" hide-details />
               <input-currency v-model="fm.price" label="Harga Jual" hide-details />
               <v-autocomplete
                  v-model="fm.category_id"
                  label="Kategory"
                  :items="categorys"
                  :search-input.sync="search_category"
                  :loading="load_category"
                  placeholder="cari kategory..."
               />
               <v-switch v-if="product_id!=='new'" v-model="fm.disabled" :true-value="1" :false-value="0" label="Disable" />
            </v-col>
            <v-col v-else cols="12" md="7">
               <div class="list-line">
                  <label class="grey--text">ID</label>
                  <div>{{fm.id}}</div>
               </div>
               <div class="list-line">
                  <label class="grey--text">Nama</label>
                  <div>{{fm.name}}</div>
               </div>
               <div class="list-line">
                  <label class="grey--text">Dibuat</label>
                  <div v-dater="fm.created_at" />
               </div>
               <div class="list-line">
                  <label class="grey--text">Stok</label>
                  <div>{{fm.stock}}</div>
               </div>
               <div class="list-line">
                  <label class="grey--text">Modal</label>
                  <div v-idr="fm.capital"/>
               </div>
               <div class="list-line">
                  <label class="grey--text">Harga Jual</label>
                  <div v-idr="fm.price"/>
               </div>
               <div class="list-line">
                  <label class="grey--text">Kategori</label>
                  <div>{{fm.category_name}}</div>
               </div>
               <div class="list-line">
                  <label class="grey--text">Status</label>
                  <div :class="`${fm.disabled===0?'blue':'red'}--text`">{{fm.disabled===0?'Aktif':'Non Aktif'}}</div>
               </div>
            </v-col>
            <v-col cols="12" md="5" class="text-center">
               <div class="font-weight-bold mb-3">Gambar Produk</div>
               <input-img :max-width="200" :max-height="200" :url="fm.image && img_url+fm.image" v-model="fm.base64_image" />
            </v-col>
         </v-card-text>
         <v-card-actions class="justify-center">
            <v-btn v-if="edit" width="220" color="primary" outlined rounded @click="save">save</v-btn>
            <v-btn v-else class="mr-auto" color="indigo" icon @click="edit=true;title='Edit'"><v-icon>mdi-pencil</v-icon></v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>
<script>
import api from '../api';
import inputImg from './inputImg.vue';
import CFG from '../config';
import InputCurrency from './inputCurrency.vue';
export default {
  components: { inputImg, InputCurrency },
   props: ['product_id'],
   data: () => ({
      title: 'Detail',
      fm: {},
      product_image: null,
      edit: false,
      load_data: false,
      load_save: false,
      categorys: [],
      search_category: null,
      load_category: false,
      img_url: CFG.img_url,
   }),
   watch: {
      product_id(val) {
         if(val) {
            if(val === 'new') {
               this.title = 'Buat';
               this.edit = true;
            } else {
               this.title = 'Detail';
               this.edit = false;
               api.get(`product/${val}`).then(rsl => {
                  if(rsl.data) {
                     this.fm = rsl.data;
                     if(rsl.data.category_name) this.categorys = [{text: rsl.data.category_name, value: rsl.data.category_id}]
                  }
               });
            }
         }
      },
      search_category(val) {
         this.load_category = true;
         clearTimeout(this.timer);
         this.timer = setTimeout(() => {
            api.get(`category?name=${val}`).then(rsl => {
               if(rsl.data) this.categorys = rsl.data.map(x => ({text: x.name, value: x.id}));
            }).finally(() => { this.load_category = false });
         }, 800);
      },
   },
   methods: {
      close(reload) {
         this.$emit('close', reload);
         this.fm = {};
      },
      save() {
         const {id, ...data} = this.fm;
         (this.product_id === 'new' ? api.create('product', data) : api.edit('product', id, data)).then(rsl => {
            if(rsl.status === 1) {
               this.$alert({title: 'Sukses', text: rsl.msg, timer: 2000});
               this.close(true);
            } else this.$alert('Ooops!', rsl.msg, 'error');
         });
      }
   }
}
</script>